<template>

    <div class="comments__table">
        <div>
            <div class="comments__table-row" v-for="(i, index) in commentsRowData" :key="index">
                <div class="comments__table-cell">
                    <div class="comments__table-cell-title" v-show="i.article_title!= null||i.post_title!= null"><strong>Название статьи:  </strong></div>
                    <div>{{i.article_title||i.post_title}}</div>
                </div>
                <div class="comments__table-cell">
                    <div class="comments__table-cell-title"><strong>Комментарий:  </strong></div>
                    <div>{{i.value||i.post_commentary}}</div>
                </div>
                <div class="comments__table-cell">
                    <div class="comments__table-cell-title"><strong>Дата комментария: </strong></div>
                    <div>{{dateFormat(i.date||i.post_commentary_date)}}</div>
                </div>
                <div class="comments__table-cell">
                    <div class="comments__table-cell-title"><strong>Ссылка на статью:  </strong></div>
                    <div> <a class="comments__table-cell-link" :href="`${i.post_link}`">{{i.post_link}}</a></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import moment from 'moment';

export default {
  name: "CommentaryRow",
  props: ['commentsRowData'],
  methods:{
         dateFormat(datePublished){
            return moment(new Date(datePublished)).format("DD-MM-YYYY")
        }
  }
}

</script>

<style scoped>

</style>