<template>
    <div class="container">
        <Header></Header>
        <div class="content">
          <div class="container-header">
            <p>Комментарии</p>
        </div>
        <div class="comentary__content">
            <commentary-content></commentary-content>
        </div>
        </div>
    </div>
</template>

<script>
import Header from "../Assets/Header";
import CommentaryContent from "./CommentaryContent";
import { CommentsData } from "@/store/comments.module";


export default {
  name: "Commentary",
  components: {
    Header,
    CommentaryContent
  },
  created() {
    this.$store.registerModule("CommentsData", CommentsData);
  },
  beforeDestroy() {
    this.$store.unregisterModule("CommentsData", CommentsData);
  },
}
</script>

<style scoped>

</style>