import CommentsService from "@/services/comments.service"

const InitialState = {
    commentsList: [],
    commentsLoaded: false
}

export const CommentsData = {
    namespaced: true,
    state: () => (InitialState),
    getters: {
        getCommentsData(state) {
            return state.commentsList;
        }
    },
    mutations: {
       updateComments(state, data) {
           state.commentsList = data;
       },
       resetCommentsState(state){
           state.commentsList = [];
       },
       loadingCommentsStatus (state, newLoadingStatus) {
            state.commentsLoaded = newLoadingStatus
       }
    },
    actions: {
        getComments({commit}, query){
            commit('loadingCommentsStatus', true)
            return CommentsService.getCommentsListById(query).then(
                result =>  {
                    commit('updateComments', result.data)
                    commit('loadingCommentsStatus', false)
                }
            )
        }
    }
  };