import axios from "axios";
import config from '@/services/config.service'

const API_URL = config['API_LOCATION'] + 'api/comments/get-all';

class CommentsService {
    async getCommentsListById(query) {
        return await axios.get(API_URL + '/?id=' + query.id)
    }
}
export default new CommentsService();