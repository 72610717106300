<template>
      <div class="comments">
          <div class="comments__nav">
               <button v-for="item in buttonsList"
                       :key="item.id"
                       class="comments__nav-button"
                       @click="loadCommentsData(item.id)"
               >
                   <img :src="require(`@/assets/comments/${item.imageSrc}`)" alt="Картинка комментариев">
               </button>
          </div>
          <div v-if="commentsDataLoaded">
              <spinner></spinner>
          </div>
          <div class="comments__list" v-else>
                <commentary-row v-for="item in commentsData"
                                :key="item.id"
                                :comments-row-data="item"
                ></commentary-row>
          </div>
      </div>
</template>

<script>

import Spinner from 'vue-simple-spinner'
import { mapState } from 'vuex';
import CommentaryRow from "./CommentaryRow";

export default {
    name: "CommentaryContent",
    data() {
       return {
          buttonsList: [
              { 'id': 1, imageSrc: 'habr.jpg'},
              { 'id': 2, imageSrc: 'tg.jpg'},
            //   { 'id': 3, imageSrc: 'fb.jpg'},
              { 'id': 4, imageSrc: 'vk.png'},
              { 'id': 5, imageSrc: 'vc.jpg'}
          ]
       }
    },
    mounted() {
        this.$store.dispatch('CommentsData/getComments', this.$route.query)
    },
    computed: mapState ({
      commentsData: state => state.CommentsData.commentsList,
      commentsDataLoaded: state => state.CommentsData.commentsLoaded,
    }),
    components: {
       Spinner,
       CommentaryRow
    },
    methods: {
      loadCommentsData(socialNetworkID) {
          this.setPageRoute({id: socialNetworkID})
          this.$store.dispatch('CommentsData/getComments', {id: socialNetworkID})
      },
      setPageRoute(routeObject){
        this.$router.push({query: Object.assign({}, this.$route.query, routeObject)})
      }
   }
}
</script>

<style scoped>

</style>